import { AxiosError, AxiosResponse } from 'axios'
import { useInfiniteQuery, useQuery } from 'react-query'

import * as requests from './requests'
import { GetListSubsegments, GetListsParams, MicroRegion, Store } from './types'
import { ResponseError, ResponseSuccess } from '../../types'

export const KEY_STORE_LIST_QUERY = 'getStoreList'

export const KEY_SUBSEGMENT_QUERY = 'getListSubsegment'

export const KEY_MICRO_REGION_QUERY = 'getListMicroRegion'

export const KEY_STATUS_TAB_QUERY = 'getListStatusTab'

export const useGetStoreListInfiniteQuery = (params: GetListsParams) =>
  useInfiniteQuery<AxiosResponse<ResponseSuccess<Store[]>>, AxiosError<ResponseError>>(
    [KEY_STORE_LIST_QUERY, params],
    ({ pageParam }) => requests.getStoreList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.metadata.pagination.current_page < lastPage.data.metadata.pagination.last_page
          ? lastPage.data.metadata.pagination.current_page + 1
          : false
    }
  )

export const useGetSubsegmentQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<GetListSubsegments[]>>, AxiosError<ResponseError>>(
    KEY_SUBSEGMENT_QUERY,
    requests.getListSubsegment
  )

export const useGetMicroRegionQuery = (enabled?: boolean) =>
  useQuery<AxiosResponse<ResponseSuccess<MicroRegion[]>>, AxiosError<ResponseError>>(
    KEY_MICRO_REGION_QUERY,
    requests.getListMicroRegion,
    {
      enabled: enabled ?? true
    }
  )

export const useGetStatusTabQuery = () =>
  useQuery<AxiosResponse<ResponseSuccess<string[]>>, AxiosError<ResponseError>>(
    KEY_STATUS_TAB_QUERY,
    requests.getListStatusTab
  )
