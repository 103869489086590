import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useGetMicroRegionQuery, useGetSubsegmentQuery } from 'integration/resources/store'
import { useBreakpointValue } from 'native-base'
import { useForm } from 'react-hook-form'

import { homeFilterWalletFormSchema } from './components/Form/schema'
import { HomeFilterWalletFormFields } from './components/Form/types'
import { UseHomeFilterScreen } from './HomeFilterScreen.types'
import { HomeSelectWalletTypes } from '../constants'

export const useHomeFilterScreen: UseHomeFilterScreen = ({ navigation, route }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const selectedWallet = route?.params?.selected_wallet

  const isExcellenceProgramWallet = useMemo(
    () => selectedWallet === HomeSelectWalletTypes.EXCELLENCE_PROGRAM,
    [selectedWallet]
  )

  const { control, formState, reset, handleSubmit, watch, getValues, setValue } =
    useForm<HomeFilterWalletFormFields>({
      resolver: yupResolver(homeFilterWalletFormSchema),
      mode: 'onChange'
    })

  const handleSubmitForm = (params: Partial<HomeFilterWalletFormFields>) => {
    navigation.navigate('Tab', {
      screen: 'Home',
      params: {
        micro_region: params.micro_region,
        segment: params.segment,
        sub_segment: params.sub_segment
      }
    })
  }

  const handleGoBack = useCallback(
    () => navigation.navigate('Tab', { screen: 'Home', params: route?.params }),
    [navigation, route.params]
  )

  const [openMicroRegion, setOpenMicroRegion] = useState(false)

  const handleSelectRegion = (region: string) => {
    setValue('micro_region', region ?? '')

    setOpenMicroRegion(false)
  }

  const [openSegment, setOpenSegment] = useState(false)

  const handleSelectSegment = (segment_code: { name: string; code: number }) => {
    setValue('segment', segment_code ?? { name: undefined, code: undefined })

    setOpenSegment(false)
  }

  const [openSubSegment, setOpenSubSegment] = useState(false)

  const handleSelectSubSegment = (sub_segment_code: { name: string; code: number }) => {
    setValue('sub_segment', sub_segment_code)

    setOpenSubSegment(false)
  }

  const formFields = watch()

  let dirtySize = 0

  if (formFields.micro_region) dirtySize++

  if (formFields.segment?.code) dirtySize++

  if (formFields.sub_segment?.code) dirtySize++

  useEffect(() => {
    reset(route.params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResetForm = () => {
    const resetedValues = {
      micro_region: undefined,
      segment: {
        name: undefined,
        code: undefined
      },
      sub_segment: {
        name: undefined,
        code: undefined
      }
    }

    navigation.setParams(resetedValues)

    reset(resetedValues)
  }

  const segmentsOptions = [
    {
      code: 1,
      name: 'SIM'
    },
    {
      code: 2,
      name: 'Veículos'
    }
  ]

  const { data: subSegmentsData, isLoading: subSegmentsDataIsLoading } = useGetSubsegmentQuery()

  const { data: microRegionData, isLoading: microRegionDataIsLoading } = useGetMicroRegionQuery()

  const subSegmentsOptions = useMemo(() => subSegmentsData?.data.data ?? [], [subSegmentsData])

  const microRegionOptions = useMemo(() => microRegionData?.data.data ?? [], [microRegionData])

  return {
    isMobile,
    control,
    dirtySize,
    getValues,
    formState,
    handleSubmit,
    handleGoBack,
    handleSubmitForm,
    handleResetForm,
    openMicroRegion,
    setOpenMicroRegion,
    handleSelectRegion,
    openSegment,
    setOpenSegment,
    handleSelectSegment,
    openSubSegment,
    setOpenSubSegment,
    handleSelectSubSegment,
    segmentsOptions,
    subSegmentsOptions,
    subSegmentsDataIsLoading,
    microRegionOptions: !isExcellenceProgramWallet ? microRegionOptions : [],
    microRegionDataIsLoading
  }
}
