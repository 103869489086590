import { AxiosResponse } from 'axios'
import { ResponseSuccess } from 'integration/types'

import { RvDescriptionItem, RvSummary } from './types'
import { BUSINESS_CODE } from '../auth'

export const KEY_GET_RV_SUMMARY_QUERY = 'useGetRVSummaryQuery'

type ProfileConfig = {
  footer_text: string
  earnings: {
    indicators: { [key: string]: { bullet_color?: string } }
  }
  statement: {
    indicators: {
      progress_bar: {
        points: {
          [key: string]: {
            placement: 'top' | 'bottom'
            has_line_indicator?: boolean
            indicator_color?: string
          }
        }
      }
      description?: { [key: string]: { slug_color?: string } }
      bottom_description?: { [key: string]: { slug_color?: string } }
    }
  }
}

const profileConfigs: Partial<Record<BUSINESS_CODE, ProfileConfig>> = {
  [BUSINESS_CODE.GOODS_AND_SERVICES]: {
    footer_text:
      'Os valores "Projetado, Baseline e Gatilho" devem ser utilizados para simples estimativas e para esclarecer eventuais pontos sobre o cálculo de comissionamento. Ele não considera eventuais proporcionalidades nem representa critérios oficiais de apuração e pagamento.',
    earnings: {
      indicators: {
        Projetado: { bullet_color: 'secondary.50' },
        Baseline: { bullet_color: 'secondary.300' }, // V1 - Remove when SIM v2 is ready
        Gatilho: { bullet_color: 'secondary.300' }, // V1 - Remove when SIM v2 is ready
        'Gatilho 1': { bullet_color: 'tabs-yellow.50' },
        'Gatilho 2': { bullet_color: 'secondary.500' },
        'Gatilho 3': { bullet_color: 'tertiary.500' }
      }
    },
    statement: {
      indicators: {
        progress_bar: {
          points: {
            B: { placement: 'top' }, // V1 - Remove when SIM v2 is ready
            G: { placement: 'bottom' }, // V1 - Remove when SIM v2 is ready
            G1: { placement: 'top', has_line_indicator: true },
            G2: { placement: 'top', has_line_indicator: true },
            G3: { placement: 'top', has_line_indicator: true }
          }
        },
        bottom_description: {
          G1: { slug_color: 'tabs-yellow.50' },
          G2: { slug_color: 'secondary.500' },
          G3: { slug_color: 'tertiary.500' }
        }
      }
    }
  },
  [BUSINESS_CODE.LEVES]: {
    footer_text:
      'Os valores "Produção, Baseline, Fidelização, Seguro SPF e Seguro Auto" devem ser utilizados para simples estimativas e para esclarecer eventuais pontos sobre o cálculo de comissionamento. Ele não considera eventuais proporcionalidades nem representa critérios oficiais de apuração e pagamento.\n\nEsse ganho atual é superestimado devido ao valor da fidelização e por não estar levando em consideração o critério do over90.',
    earnings: {
      indicators: {
        Produção: { bullet_color: 'secondary.500' }
      }
    },
    statement: {
      indicators: {
        progress_bar: {
          points: {
            B: { placement: 'top', has_line_indicator: true, indicator_color: 'secondary.500' },
            G: { placement: 'bottom' },
            G1: { placement: 'top', has_line_indicator: true, indicator_color: 'primary.500' },
            G2: { placement: 'bottom' }
          }
        },
        description: {
          B: { slug_color: 'secondary.500' }
        }
      }
    }
  },
  [BUSINESS_CODE.PESADOS]: {
    footer_text:
      'Os valores "Produção, Baseline e Gatilho" devem ser utilizados para simples estimativas e para esclarecer eventuais pontos sobre o cálculo de comissionamento. Ele não considera eventuais proporcionalidades nem representa critérios oficiais de apuração e pagamento.\n\nEsse ganho atual é superestimado devido ao valor da fidelização e por não estar levando em consideração o critério do over90.',
    earnings: {
      indicators: {
        Produção: { bullet_color: 'secondary.500' }
      }
    },
    statement: {
      indicators: {
        progress_bar: {
          points: {
            B: { placement: 'top', has_line_indicator: true, indicator_color: 'secondary.500' },
            G1: { placement: 'bottom', has_line_indicator: true },
            G2: { placement: 'bottom' }
          }
        },
        description: {
          B: { slug_color: 'secondary.500' }
        }
      }
    }
  }
}

const getProfileConfig = (businessCode: BUSINESS_CODE): ProfileConfig => {
  return profileConfigs[businessCode] ?? profileConfigs[BUSINESS_CODE.GOODS_AND_SERVICES]!
}

const serializeEarningsIndicators = (
  indicators: RvSummary['resume']['earnings']['indicators'],
  businessCode: BUSINESS_CODE
) => {
  const config = getProfileConfig(businessCode)

  return indicators.map((indicator) => ({
    ...indicator,
    bullet_color: config.earnings.indicators[indicator.title]?.bullet_color
  }))
}

const serializeStatementProgressBarPoints = (
  points: RvSummary['statement']['indicators'][0]['progress_bar']['points'],
  businessCode: BUSINESS_CODE,
  indicatorIndex: number
) => {
  const config = getProfileConfig(businessCode)

  return points.map((point, index) => {
    let configKey = point.slug

    // Special handling for LEVES
    if (businessCode === BUSINESS_CODE.LEVES && indicatorIndex > 0) {
      configKey = index === 0 ? 'G1' : 'G2'
    }

    const baseConfig = config.statement.indicators.progress_bar.points[configKey] || {}

    // Temporary Add color for B points in GOODS_AND_SERVICES based on index
    if (businessCode === BUSINESS_CODE.GOODS_AND_SERVICES && point.slug === 'B') {
      return {
        ...point,
        ...baseConfig,
        indicatorColor: indicatorIndex === 0 ? 'secondary.500' : 'tertiary.500'
      }
    }

    return {
      ...point,
      ...baseConfig
    }
  })
}

const serializeDescriptionItems = (
  items: RvDescriptionItem[],
  configItems: Record<string, { slug_color?: string }>,
  businessCode: BUSINESS_CODE,
  indicatorIndex: number
) => {
  return items.map((item) => {
    // Temporary handling for B slug in GOODS_AND_SERVICES description
    if (businessCode === BUSINESS_CODE.GOODS_AND_SERVICES && item.slug === 'B') {
      return {
        ...item,
        slug_color: indicatorIndex === 0 ? 'secondary.500' : 'tertiary.500'
      }
    }

    return {
      ...item,
      slug_color: item.slug ? configItems[item.slug]?.slug_color : undefined
    }
  })
}

const serializeStatementIndicators = (
  indicators: RvSummary['statement']['indicators'],
  businessCode: BUSINESS_CODE
) => {
  const config = getProfileConfig(businessCode)

  return indicators.map((indicator, index) => ({
    ...indicator,
    progress_bar: {
      ...indicator.progress_bar,
      current_progress: Number(indicator.progress_bar.current_progress.toFixed(2)),
      current_progress_value:
        Number(indicator?.progress_bar?.current_progress_value?.toFixed(2)) ?? undefined,
      points: serializeStatementProgressBarPoints(
        indicator.progress_bar.points,
        businessCode,
        index
      )
    },
    description: serializeDescriptionItems(
      indicator.description,
      config.statement.indicators.description ?? {},
      businessCode,
      index
    ),
    bottom_description: indicator.bottom_description
      ? serializeDescriptionItems(
          indicator.bottom_description,
          config.statement.indicators.bottom_description ?? {},
          businessCode,
          index
        )
      : undefined
  }))
}

export const getRvSummary = (
  response: AxiosResponse<ResponseSuccess<RvSummary>>,
  businessCode: BUSINESS_CODE
): AxiosResponse<ResponseSuccess<RvSummary>> => {
  const serializedData: RvSummary = {
    ...response.data.data,
    resume: {
      ...response.data.data.resume,
      earnings: {
        ...response.data.data.resume.earnings,
        footer_text: getProfileConfig(businessCode).footer_text,
        indicators: serializeEarningsIndicators(
          response.data.data.resume.earnings.indicators,
          businessCode
        )
      }
    },
    statement: {
      ...response.data.data.statement,
      indicators: serializeStatementIndicators(
        response.data.data.statement.indicators,
        businessCode
      )
    }
  }

  return {
    ...response,
    data: {
      ...response.data,
      data: serializedData
    }
  }
}
